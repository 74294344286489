*{
    margin: 0;
    padding: 0;
}
.main{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../images/johny-goerend-KB9r_hTzyeQ-unsplash (1).jpg');
    background-size: cover;
    background-position: center;
}
.sub-main{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    height: 400px;
    width: 35%;
    /*background-color: rgba(252, 252, 252, 0.5);*/
    background-image: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 0.3) 20%,
            rgba(255, 255, 255, 0.3) 80%,
            rgba(255, 255, 255, 1) 100%);
}

.container-image{
    background-color: rgba(0, 120, 208, 0.05);
    border-radius: 50%;
    align-items: center;
    height: 115px;
    width: 115px;
}
.profile{
    height: 100%;
    width: 100%;
    border-radius: 50%;
    object-fit: contain;
}

.login-form{
    display: flex;
    justify-content: space-around;
    flex-direction: column;
}
.login-form p{
    padding-top: 20px;
    color: red;
}
.form-item{
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.form-box{
    padding: 30px ;
}
.login-form h1{
    text-align: center;
    padding-top: 50px;
    color: #4d6d9f;
}
.form-item input{
    width: 300px;
    height: 50px;
    border-radius: 60px;
    box-shadow: inset 0 0 25px 0 #acb7c7;
    border: none;
    outline: none;
    background-color: #fff;

}
.img-user, .img-pass{
    height: 25px;
    width: 25px;
    z-index: 2000;
    padding: 15px;
}
.name{
    padding-left: 70px;
    font-size: 20px;
}

.button-71 {
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 18px;
    text-align: center;

    width: 380px;
    height: 50px;
    border-radius: 60px;
    background-color: #4d6d9f;
    border: 0;
    color: #fff;
    cursor: pointer;
    font-family: system-ui,-apple-system,system-ui,"Segoe UI",Roboto,Ubuntu,"Helvetica Neue",sans-serif;
    font-weight: 600;
    outline: 0;
    padding: 16px 21px;
    position: relative;
    transition: all .3s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

.button-71:before {
    background-color: initial;
    background-image: linear-gradient(#fff 0, rgba(255, 255, 255, 0) 100%);
    border-radius: 125px;
    content: "";
    height: 50%;
    left: 4%;
    opacity: .5;
    position: absolute;
    top: 0;
    transition: all .3s;
    width: 92%;
}

.button-71:hover {
    box-shadow: rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .1) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px;
    transform: scale(1.05);

}

@media (min-width: 768px) {
    .button-71 {
        padding: 16px 48px;
    }
}
.login-button{
    padding-top: 25px;
}