
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body{
    width: 100%;
    height: 100vh;
    background-image: url('../images/johny-goerend-KB9r_hTzyeQ-unsplash (1).jpg');
    background-size: cover;
    background-position: center;
    z-index: -1; /* Make sure the background image is behind everything else */
}
/* App Container */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
}

.container {
    display: flex;
    margin-left: 250px; /* Account for sidebar width */
}

/* Table Styling */
table {
    border-collapse: collapse;
    margin-bottom: 20px;
    width: calc(100% - 250px);
    table-layout: fixed;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    font-size: 14px;
    background: rgba(255,255,255,0.8);
}
.third-table-th1 , .third-table-th3{
    width:100px;
}
.third-table-th2{
    width:400px;
}
.third-table-tr1 , .third-table-tr3{
    width:100px;
}
.third-table-tr2{
    width:400px;
}

td {
    /*padding: 8px;*/
    max-width:25%;
    word-wrap: break-word;
    padding: 12px 15px;
    text-align: left;
    border: 1px solid #000000;
}

th {
    background-color: #f2f2f2;
    text-align: left;
    max-width:10%;
    word-wrap: break-word;
    padding: 12px 15px;
    border: 1px solid #000000;
}

tr:hover {
    background-color: #ffffff;
}
/* Inputs */
input {
    width: 100%;
    border: none;
    outline: none;
    background-color: transparent;

}

/* Buttons */
button {
    padding: 8px 12px;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
    margin: 4px;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #0056b3;
}

/* Responsive Styling */
@media (max-width: 768px) {
    .container {
        flex-direction: column;
        margin-left: 0;
    }

    table {
        margin-left: 20px;
        margin-right: 20px;
    }
}
